<template>
  <van-cell-group>
    <van-cell v-for="item in data" :key="item.id" :title="item.plateNo" :center="true">
      <div slot="default">
        <van-tag :type="tagType(item)" plain>{{ item.status | dictBkStatus }}</van-tag>
        <van-tag v-if="showPayInfo" :type="item.paid===true?'success':'danger'" plain style="margin-left:.5rem">{{ item.paid===true?'已支付':'未支付' }}</van-tag>
      </div>
      <div slot="label" class="bk-desc">
        <div><van-icon name="clock-o" /><span>{{ item.date }} {{ item.time }}</span></div>
        <div v-if="showPayInfo"><van-icon name="gold-coin-o" /><span>{{ item.payCode || '' }} {{ item.payPrice | money }}</span></div>
      </div>
      <van-icon v-if="item.status==='BOOKING'" slot="right-icon" name="ellipsis" class="bk-more" @click="showAct(item)" />
    </van-cell>
    <NotFoundRecords v-if="data.length===0" />
  </van-cell-group>
</template>

<script>
import NotFoundRecords from './NotFoundRecords'
export default {
  components: { NotFoundRecords },
  props: {
    data: { type: Array, default: function() { return [] } },
    showPayInfo: { type: Boolean, default: false }
  },
  methods: {
    showAct(row) {
      this.$emit('onAct', row)
    },
    tagType(bk) {
      switch (bk.status) {
        case 'BOOKING': return 'success'
        case 'ONTIME': return 'primary'
        case 'EXPIRED': return 'danger'
        case 'DONE': return 'default'
        case 'CANCELLED': return 'warning'
        default : return ''
      }
    }
  }

}
</script>

<style scoped>
.bk-desc{
    display: flex;
    flex-direction: column;
}
.bk-desc > div{
    display: flex;
    align-items: center;
}
.bk-desc  span{
    padding-left: .5rem;
}
.bk-more {
    padding: 10px 0px 10px var(--mp);
}
</style>
