<template>
  <div class="page-ctx">
    <van-tabs v-model="tabName" color="#1989fa" :swipeable="true" :lazy-render="true">
      <van-tab name="all" title="全部">
        <BkRecords :data="pageData.data" :show-pay-info="isPayEnable && inWhiteList(user.phone)" @onAct="showActSheet" />
      </van-tab>
      <van-tab name="run" title="未完成">
        <BkRecords :data="unclosed" :show-pay-info="isPayEnable && inWhiteList(user.phone)" @onAct="showActSheet" />
      </van-tab>
      <van-tab name="done" title="已完成">
        <BkRecords :data="closed" :show-pay-info="isPayEnable && inWhiteList(user.phone)" @onAct="showActSheet" />
      </van-tab>
    </van-tabs>
    <van-action-sheet v-model="actSheetShow" title="选择操作：" :actions="actSheetActions" cancel-text="取消" @select="onSelectAct" />
  </div>
</template>

<script>
import WxPayApi from '@/apis/WxPayApi'
import BKApi from '@/apis/BKApi'
import BkRecords from '@/components/BkRecords'
import { mapGetters } from 'vuex'
export default {
  components: { BkRecords },
  data() {
    return {
      actSheetShow: false,
      actSheetActions: [],
      tabName: 'run',
      statusRun: ['BOOKING', 'ONTIME'],
      statusDone: ['DONE', 'EXPIRED', 'CANCELLED'],
      params: {
        kw: '',
        filter: [],
        sort: []
      },
      pageData: {
        data: [],
        total: 0,
        totalPages: 0,
        pageNum: 1,
        pageSize: 20
      }
    }
  },
  computed: {
    unclosed: function() {
      const items = []
      this.pageData.data.forEach(item => {
        if (['BOOKING', 'ONTIME'].indexOf(item.status) >= 0) {
          items.push(item)
        }
      })
      return items
    },
    closed: function() {
      const items = []
      this.pageData.data.forEach(item => {
        if (['DONE', 'EXPIRED', 'CANCELLED'].indexOf(item.status) >= 0) {
          items.push(item)
        }
      })
      return items
    },
    ...mapGetters({
      'user': 'user',
      'isPayEnable': 'wxpay/isPayEnable',
      'inWhiteList': 'wxpay/inWhiteList'
    })
  },
  created() {
    this.loadBKs()
  },
  methods: {
    loadBKs() {
      BKApi.getCustBKs().then(rsp => {
        this.pageData = rsp
      })
    },
    showActSheet(bk) {
      if (bk.status === 'BOOKING') {
        this.actSheetActions = [
          { name: '取消预约', act: 'CANCEL', id: bk.id }
        ]
        if (this.isPayEnable && this.inWhiteList(this.user.phone) && bk.paid === false) {
          this.actSheetActions.push({ name: '现在支付', act: 'TOPAY', id: bk.id })
        }
      }
      this.actSheetShow = true
    },
    onSelectAct(act) {
      if (act.act === 'CANCEL') {
        this.$dialog.confirm({
          title: '操作确认',
          message: '确认要取消预约吗？'
        }).then(() => {
          BKApi.custCancelBK(act.id).then(rsp => {
            this.loadBKs()
          })
        })
      } else if (act.act === 'TOPAY') {
        this.wxAuthUrl = WxPayApi.getWxAuthUrl(
          this.$store.state.wxpay.wxAuthUrl
          , this.$store.state.wxpay.appId
          , this.$store.state.wxpay.entryUrl
          , act.id)
        location.replace(this.wxAuthUrl)
      }
      this.actSheetShow = false
    },
    tagType(bk) {
      switch (bk.status) {
        case 'BOOKING': return 'success'
        case 'ONTIME': return 'primary'
        case 'EXPIRED': return 'danger'
        case 'DONE': return 'default'
        case 'CANCELLED': return 'warning'
        default : return ''
      }
    }
  }
}
</script>

<style scoped>
.bk-desc{
  display: flex;
  align-items: center;
}
.bk-desc >span{
  padding-left:5px;
}
.bk-more {
  padding: 10px 0px 10px var(--mp);
}
</style>
